<template>
  <div class="j-list-text-title" v-if="titleData.name">
    <img class="j-list-text-title-img" src="../assets/icon1.png" alt="">
    <span class="j-list-text-title-name">{{titleData.name}}</span>
    <img class="company-name-bg" :src="titleData.nameBg" alt="">
  </div>
</template>

<script>
export default {
  props: {
    titleData: {
      type: Object,
      default () {
        return {
          name: '',
          nameBg: ''
        };
      }
    },
  }
}
</script>

<style scoped>
.j-list-text-title{
  position: relative;
  height: 100px;
  width: 525px;
  left: -54px;
}
.j-list-text-title-img{
  position: absolute;
  left: 0;
  bottom: 2px;
  width: 32px;
  height: 32px;
}
.j-list-text-title-name{
  position: absolute;
  left: 54px;
  bottom: 0;
  height: 48px;
  font-size: 34px;
  font-family: PingFang SC;
  font-weight: 800;
  line-height: 48px;
  color: #393939;
  opacity: 1;
}
.company-name-bg{
  position: absolute;
  left: 54px;
  top:0;
  /*width: 471px;*/
  height: 84px;
  z-index: 1;
}
</style>
