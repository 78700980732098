<template>
  <div>
    <j-header></j-header>
    <div class="news">
      <div class="news-introduce">
        <div class="news-introduce-con">
          <j-text-title :title-data="newsData"></j-text-title>
          <div class="news-introduc-box">
            <img class="news-img" src="../../assets/news.png" alt="">
            <div class="news-p-w">
              <p class="news-p-title">安徽商会领导一行人来我司莅临指导</p>
              <p>
                2020年12月13日，杭州市安徽商会党支部书记江成余 ，副会长霍银斌
                等领导来我司参观考察。公司董事长张守见、总经理谢建、项目经理蒋
                琳等人陪同参观。
              </p>
            </div>
          </div>
        </div>
        <div class="new-bg-card1"></div>
        <img class="news-step1" src="../../assets/news-step1.png" alt="">
      </div>
      <div class="news-introduce-two">
        <div class="news-introduce-two-con">
          <j-list :listData="newsTwoData">
            <div slot="listText" class="news-p-w">
              <p>
                各位领导参观了我公司办公区域、产品陈列区与演示区。在参观过程
                中，张总向商会领导一行详细介绍了公司现有业务逻辑，同时对企业
                目前所运行项目进行了详细说明。为了满足社会需要，久冠网络每年
                都投入大量人力物力，突破技术瓶颈、加大研发力度、提升研发水平、
                努力研发符合市场需要的多功能、多场景、多业务、多范围的产品场
                景需求。
              </p>
              <p>
                在欢迎会上，张总对各位领导的莅临表示热烈欢迎，同时对公司发展
                历程做了详细介绍，对企业现状及当下经营策略进行阐述，对企业未
                来发展规划进行说明。
              </p>
            </div>
            <div slot="listImg">
              <img class="news-img" src="../../assets/news2.png" alt="">
            </div>
          </j-list>
        </div>
        <img class="news-step2" src="../../assets/news-step2.png" alt="">
      </div>
      <div class="news-introduce-three">
        <div class="news-introduce-three-con">
          <j-list :listData="newsThreeData">
            <div slot="listText" class="news3-p-w">
              <p>
                此次会议，商会各领导一行对张总等安徽籍创业人员追寻自主创业、
                为社会提供更多就业机会、努力实现社会价值的高尚情操予以表彰。在
                了解久冠网络等安徽籍企业发展历程和未来规划后，双方就当下企业创
                业及企业管理策略进行深入沟通。久冠网络采用的科学管理方法也得到
                商会领导一行的称赞。
              </p>
            </div>
            <div slot="listImg">
              <img class="news-img" src="../../assets/news3.png" alt="">
            </div>
          </j-list>
        </div>
        <img class="news-step3" src="../../assets/news-step3.png" alt="">
        <div class="new-bg-card2"></div>
      </div>
    </div>
    <j-foot></j-foot>
  </div>

</template>

<script>
import JList from "@/components/JList";
import nBg from "@/assets/news-name-bg.png";
import JTextTitle from "@/components/JTextTitle";
export default {
  components: {
    JTextTitle,
    JList
  },
  data() {
    return {
      newsData: {
        name: '新闻动态',
        nameBg: nBg,
        type: 'tRight'
      },
      newsTwoData: {
        type: 'tLeft'
      },
      newsThreeData: {
        type: 'tRight'
      }
    }
  }
}
</script>

<style scoped>
.news-introduce{
  height: 1080px;
  width: 100%;
  position: relative;
}
.news-introduce-con{
  position: absolute;
  width: 1200px;
  left: 50%;
  margin-left: -600px;
  top: 146px;
  z-index: 10;
}
.news-img{
  width: 578px;
  height: 427px;
}
.news-p-w{
  width: 544px;
}
.news-introduce-two{
  position: relative;
  width: 100%;
  height: 1080px;
  background: url("../../assets/news-bg2.png") no-repeat center center;
}
.news-introduce-two-con{
  position: absolute;
  top: 306px;
  width: 1200px;
  left: 50%;
  margin-left: -600px;
}
.news-introduce-three{
  position: relative;
  width: 100%;
  height: 1080px;
}
.news-introduce-three-con{
  position: absolute;
  width: 1200px;
  left: 50%;
  margin-left: -600px;
  top: 305px;
}
.news3-p-w{
  width: 507px;
}
.news-introduc-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 161px;
}
.news-introduc-box .news-p-w{
  text-align: left;
  width: 498px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 30px;
  color: #474747;
  opacity: 1;
}
.news-introduc-box .news-p-w p{
  margin-top: 42px;
}
.new-bg-card1{
  position: absolute;
  top:541px;
  left: 0;
  width: 475px;
  height: 380px;
  background-color: #4977E8;
  opacity: 0.05;
  z-index: 1;

}
.news-step1{
  position: absolute;
  right: 0;
  bottom: 0;
  width: 312px;
  height: 364px;
}
.news-step2{
  position: absolute;
  left: 30px;
  top: 652px;
  width: 312px;
  height: 364px;
}
.new-bg-card2{
  position: absolute;
  width: 446px;
  height: 380px;
  bottom: 64px;
  right: 0;
  background-color: #4977E8;
  opacity: 0.05;
}
.news-step3{
  position: absolute;
  width: 312px;
  height: 364px;
  top:101px;
  right: 48px;
}
.news-p-title{
  height: 33px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 800;
  line-height: 33px;
  color: #393939;
  opacity: 1;
}

</style>
