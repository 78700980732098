<template>
  <div class="j-list">
    <div v-if="listData.type === 'tLeft'">
      <slot name="listImg"></slot>
    </div>
    <div class="j-list-text">
      <j-text-title :titleData="listData"></j-text-title>
<!--      <div class="j-list-text-title" v-if="listData.name">-->
<!--        <img class="j-list-text-title-img" src="../assets/icon1.png" alt="">-->
<!--        <span class="j-list-text-title-name">{{listData.name}}</span>-->
<!--        <img class="company-name-bg" :src="listData.nameBg" alt="">-->
<!--      </div>-->
      <div class="j-list-text-con">
        <slot name="listText"></slot>
      </div>
    </div>
    <div v-if="listData.type === 'tRight'">
      <slot name="listImg"></slot>
    </div>
  </div>
</template>

<script>
import JTextTitle from "@/components/JTextTitle";
export default {
  components: {JTextTitle},
  props: {
    listData: {
      type: Object,
      default () {
        return {
          name: '',
          nameBg: '',
          type: 'tRight'
        };
      }
    },
  }

}
</script>

<style scoped>
.j-list{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.j-list-text{
  /*width: 417px;*/
}
.j-list-text-img{
  width: 440px;
  height: 550px;
}




.j-list-text-con{
  /*width: 599px;*/
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 30px;
  color: #474747;
  opacity: 1;
  text-align: left;
}
.j-list-text-con p{
  margin-top: 42px;
  font-size: 16px;
}

</style>
